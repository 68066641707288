export const links = [
    {
        name: "Home",
        path: '/'
    },
    {
        name: "Shop",
        path: '/shop'
    },
    {
        name: "Roster",
        path: '/roster'
    },
    {
        name: "Events",
        path: '/events'
    },
    {
        name: "News",
        path: '/news'
    },
    {
        name: "About",
        path: '/about'
    }
]

