import Header from '../../components/Header'

import './home.css'

const Home = () => {
  return (
    <div>
      < Header />
    </div>
  )
}

export default Home